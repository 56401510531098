import React from "react";
import { PageProps } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import SEO from "../../components/seo";

const RoomPage: React.FC<PageProps> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const { t: t2 } = useTranslation("navigation");
  const name = t("seththee_name");
  const description = t("seththee_description", { name });
  const title = `${t("hotel")} ${name} | ${t2("rooms")}`;
  return (
    <main className="full-centered">
      <SEO pageTitle={title} language={language} description={description} />
      <h1>{t2("rooms")}</h1>
      <p>
        We have rooms with twin beds or a double bed and family rooms with an
        addiotional single or double bed.
        <br /> All rooms have balcony, bathroom with hot shower, aircon, fridge,
        cable TV and free WiFi.
      </p>
    </main>
  );
};

export default RoomPage;
